/*TO DO set right-contact-info for 9961px and smaller*/
body, html {
    background-color: #010001;
}
.about-project-header {
    height: 75px;
    margin-top: calc(19px + 0 * (100vw - 960px) / 960);
}

@media (max-width: 566px) {
    .top-about-contact-links {
        display: none;
    }
    .about-contact-links {
        font-family: SofiaLight, sans-serif;
        letter-spacing: .3em;
        text-transform: uppercase;
        font-size: calc(12px + 0 * (100vw - 960px) / 960);
        color: #707175;
        transition: all 1s;
        padding: 0 15px;
    }
    .bottom-about-contact-links {
        margin-top: 8rem;
        display: flex;
        justify-content: space-between;    }
}

@media (min-width: 567px) {
    .bottom-about-contact-links {
        display: none;
    }
    .about-contact-links {
        float: left;
        line-height: 1.8;
        text-decoration: none;
        margin-top: calc(50px + 0 * (100vw - 960px) / 960);
        font-family: SofiaLight, sans-serif;
        letter-spacing: .3em;
        text-transform: uppercase;
        font-size: calc(13px + 0 * (100vw - 960px) / 960);
        color: #707175;
        transition: all 1s;
        padding: 0 15px;
    }
}

.about-video-container {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
}

.github-codepen-links {
    font-family: SofiaLight, sans-serif;
    text-decoration: none;
    color: #707175;
    float: right;
    text-transform: uppercase;
    font-size: calc(13px + 0 * (100vw - 960px) / 960);
    line-height: 1.8;
    letter-spacing: .3em;
    position: fixed;
    right: 0;
    z-index: 1000;
    margin-right: calc(50px + 0 * (100vw - 960px) / 960);
}

.about-project-container {
    margin: 0 auto;
    width: 100%;
}


@media (max-width: 961px) {
    .about-project-container {
        display: block;
    }
}

.set-max-width {
    max-width: 96vw;
    text-align: center;
    /*max-width: 1130px;*/
}

@media (max-width: 1140px) {
    .set-max-width {
        max-width: 100vw;
margin: 0    }
}

/* Video */

.about-video {
    max-width: 100%;
    padding-top: 1em;
    height: auto;
}

/* Controls */

.controlsContainer {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    z-index: 20;
}

.controls {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.controlsIcon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: 1rem 10rem;
    opacity: .7;
}
@media (max-width: 580px) {
    .controlsIcon {
        margin: 1rem 6rem;
    }
}

.controlsIcon--small {
    width: 32px;
    height: 32px;
    cursor: pointer;
    opacity: .7;
}

/* The time controls section */

.timecontrols {
    display: flex;
    justify-content: center;
    margin-top: -3.8rem;
}

.time_progressbarContainer {
    background-color: #2C1E69;
    display: flex;
    border-radius: 15px;
    width: 85%;
    height: 5px;
    z-index: 30;
    position: relative;
    margin: -5px 20px;
}

.time_progressBar {
    border-radius: 15px;
    background-color: #1a0e49;
    height: 100%;
}

.controlsTime {
    color: #46329DFF;
    margin: -10px 10px;
}

.about-header {
    height: 75px;
    margin-top: calc(19px + 0 * (100vw - 960px) / 960);
}
